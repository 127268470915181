 /*----- flex 容器 ----*/

 .flex-container {
     display: flex;
 }

 .flex-space-between {
     justify-content: space-between;
 }

 .flex-space-around {
     justify-content: space-around;
 }

 .flex-x-center {
     justify-content: center;
 }

 .flex-y-start {
     align-items: flex-start;
 }

 .flex-x-end {
     justify-content: flex-end;
 }

 .flex-y-end {
     align-items: flex-end;
 }

 .flex-y-center {
     align-items: center;
 }

 .flex-center {
     align-items: center;
     justify-content: center;
 }

 .layout-vertical {
     flex-direction: column;
 }

 .flex-wrap {
     flex-wrap: wrap;
 }

 .flex-block {
     flex-grow: 1;
 }

 .flex-shrink {
     flex-shrink: 1;
 }

 .flex-fixed {
     flex-shrink: 0;
     flex-grow: 0;
 }

 .plane {
     padding: 24px 16px;
     background: var(--color-bg);
     color: var(--color-title);
 }

 .full-screen {
     width: 100%;
     min-height: 100vh;
     box-sizing: border-box;
 }