.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  min-height: 200px;

  &.full-screen {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }
}

.lottie {
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 8px;
}


.line-scale-pulse-out>div {
  width: 4px;
  height: 44px;
  display: inline-block;
  border-radius: 2px;
  margin: 2px;
  background-color: #2c7bff;
}

@keyframes rotate-circle {
  from {
    transform: rotate(0);
    transform-origin: center center;
  }

  to {
    transform: rotate(360deg);
    transform-origin: center center;
  }
}

@keyframes line-scale-pulse-out {

  0%,
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
}

.line-scale-pulse-out>div {
  -webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}

.line-scale-pulse-out>div:nth-child(2),
.line-scale-pulse-out>div:nth-child(4) {
  -webkit-animation-delay: -0.4s !important;
  animation-delay: -0.4s !important;
}

.line-scale-pulse-out>div:nth-child(1),
.line-scale-pulse-out>div:nth-child(5) {
  -webkit-animation-delay: -0.2s !important;
  animation-delay: -0.2s !important;
}