:root {
  --color-bg: #fff;
  --color-title: #061329;
  --color-text: #768091;
  --color-desc: #8f8f8f;
  --color-line: #eee;
  --color-shadow: rgba(100, 100, 100, 0.1);
  --color-brand-title: #142d58;
  --color-brand-text: #a6aebd;
  --color-brand-bg: #768091;
  --color-brand: #2e4a9d;
  --color-link: #2e4a9d;
  --color-btn-bg: #999;
  --color-btn-text: #1b1b1b;
  --color-000: #000;
  --color-c5: #c5c5c5;
  --color-aaa: #aaa;
  --color-fff: #fff;
  --color-fa: #fafafa;
  --color-f5: #f5f5f5;
  --color-eee: #eee;
  --color-ddd: #ddd;
  --color-ccc: #ccc;
  --color-999: #999;
  --color-777: #777;

}

@media (prefers-color-scheme:dark) {
  :root {
    --color-bg: #151517;
    --color-title: #fff;
    --color-text: #a1a1a1;
    --color-desc: #5b5b5b;
    --color-line: #2e2e2e;
    --color-shadow: rgba(100, 100, 100, 0.1);
    --color-brand: #3966EC;
    --color-link: #3966EC;
    --color-brand-title: #3966EC;
    --color-brand-text: #768091;
    --color-brand-bg: #151517;
    --color-btn-bg: #424242;
    --color-btn-text: #fff;
    --color-000: #fff;
    --color-c5: #444;
    --color-aaa: #a1a1a1;
    --color-fff: #151517;
    --color-fa: #222222;
    --color-f5: #252525;
    --color-eee: #333333;
    --color-ddd: #444444;
    --color-ccc: #555555;
    --color-999: #777777;
    --color-777: #999999;

  }
}

html,
body {
  min-height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  line-height: 1.875;
  color: var(--color-title);
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: auto;
  overflow-x: hidden;
  background: var(--color-bg);
}

img,
video,
table {
  max-width: 100%;
}

.block-show {
  display: block !important;
  width: 100%;
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.tips {
  color: var(--color-c5);
}

.tips.sm-size {
  font-size: 16px;
}

.page-title {
  font-size: 21px;
  color: var(--color-title);
  margin-bottom: 15px;
  font-weight: bold;
}

.page-content {
  font-size: 14px;
  color: var(--color-title);


  p {
    margin-bottom: 20px;
    font-size: 14px;
  }

  span[style*="#000"],
  p[style*="#000"],
  div[style*="#000"] {
    background: transparent !important;
    color: var(--color-title) !important;
  }
}

.fixed {
  position: fixed;
  z-index: 1103;
}

.full-screen {
  width: 100vw;
  min-height: 100vh;
  background: var(--color-bg);
  box-sizing: border-box;
}

.scroll-fixed {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.icon-404 {
  width: 140px;
  height: 140px;
  margin: 0 auto 50px;
  background: url("/static/404.png") no-repeat center center;
  background-size: contain;
}

.link {
  color: var(--color-link);
}

.list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-gutter {

  >span,
  >div,
  >p {
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.list-file {
  li {
    padding: 12px 0 12px 30px;
    box-shadow: 0 1px 0 var(--color-shadow);
    font-size: 15px;
    color: var(--color-title);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background: url("/static/file.svg") no-repeat left center;
    background-size: 22px 22px;
  }
}

.am-carousel-wrap-dot>span {
  border-radius: 0;
  width: 15px;
  height: 2px;
}

.am-carousel-wrap-dot-active>span {
  background: var(--color-brand);
}

.close {
  position: relative;
  width: 30px;
  height: 30px;
  background: url("/static/close.png") no-repeat center center;
}

.btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 10px;
}

.btn-back {
  line-height: 40px;
  text-align: center;
  background: var(--color-btn-bg);
  display: block;
  color: var(--color-btn-text);
  font-weight: bold;
}

.btn-text {
  text-decoration: none;
  outline: none;
  appearance: none;
  border: none;
  background: transparent;
  text-align: center;
  color: var(--color-desc);
  cursor: pointer;
}

.am-modal-title {
  line-height: 1.5;
}

.text-huge {
  font-size: 24px;
}

.text-big {
  font-size: 18px;
}

.text-small {
  font-size: 12px;
}

.text-tiny {
  font-size: 10px;
}

.visible-hide {
  visibility: hidden;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  appearance: none;

  &.icon-online,
  &.icon-back,
  &.icon-search {
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.icon-online {
    background-image: url("/static/icon/online.png");
  }

  &.icon-back {
    background-image: url("/static/icon/arrow-left.png");
  }

  &.icon-search {
    background-image: url("/static/icon/search.png");
  }

  &.icon-arrow-right {
    background: url("/static/icon/arrow-right.png") no-repeat;
    background-size: contain;
    width: 8px;
    height: 14px;
  }
}

.line-scale-pulse-out>div {
  width: 2px;
  height: 33px;
  display: inline-block;
  border-radius: 2px;
  margin: 2px;
  background-color: var(--color-brand);
}

@keyframes rotate-circle {
  from {
    transform: rotate(0);
    transform-origin: center center;
  }

  to {
    transform: rotate(360deg);
    transform-origin: center center;
  }
}

@keyframes line-scale-pulse-out {

  0%,
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
}

.line-scale-pulse-out>div {
  -webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}

.line-scale-pulse-out>div:nth-child(2),
.line-scale-pulse-out>div:nth-child(4) {
  -webkit-animation-delay: -0.4s !important;
  animation-delay: -0.4s !important;
}

.line-scale-pulse-out>div:nth-child(1),
.line-scale-pulse-out>div:nth-child(5) {
  -webkit-animation-delay: -0.2s !important;
  animation-delay: -0.2s !important;
}

iframe {
  // display: none !important;
}